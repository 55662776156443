/*
    http://www.JSON.org/json2.js
    2011-02-23

    Public Domain.

    NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK.

    See http://www.JSON.org/js.html


    This code should be minified before deployment.
    See http://javascript.crockford.com/jsmin.html

    USE YOUR OWN COPY. IT IS EXTREMELY UNWISE TO LOAD CODE FROM SERVERS YOU DO
    NOT CONTROL.


    This file creates a global JSON object containing two methods: stringify
    and parse.

        JSON.stringify(value, replacer, space)
            value       any JavaScript value, usually an object or array.

            replacer    an optional parameter that determines how object
                        values are stringified for objects. It can be a
                        function or an array of strings.

            space       an optional parameter that specifies the indentation
                        of nested structures. If it is omitted, the text will
                        be packed without extra whitespace. If it is a number,
                        it will specify the number of spaces to indent at each
                        level. If it is a string (such as '\t' or '&nbsp;'),
                        it contains the characters used to indent at each level.

            This method produces a JSON text from a JavaScript value.

            When an object value is found, if the object contains a toJSON
            method, its toJSON method will be called and the result will be
            stringified. A toJSON method does not serialize: it returns the
            value represented by the name/value pair that should be serialized,
            or undefined if nothing should be serialized. The toJSON method
            will be passed the key associated with the value, and this will be
            bound to the value

            For example, this would serialize Dates as ISO strings.

                Date.prototype.toJSON = function (key) {
                    function f(n) {
                        // Format integers to have at least two digits.
                        return n < 10 ? '0' + n : n;
                    }

                    return this.getUTCFullYear()   + '-' +
                         f(this.getUTCMonth() + 1) + '-' +
                         f(this.getUTCDate())      + 'T' +
                         f(this.getUTCHours())     + ':' +
                         f(this.getUTCMinutes())   + ':' +
                         f(this.getUTCSeconds())   + 'Z';
                };

            You can provide an optional replacer method. It will be passed the
            key and value of each member, with this bound to the containing
            object. The value that is returned from your method will be
            serialized. If your method returns undefined, then the member will
            be excluded from the serialization.

            If the replacer parameter is an array of strings, then it will be
            used to select the members to be serialized. It filters the results
            such that only members with keys listed in the replacer array are
            stringified.

            Values that do not have JSON representations, such as undefined or
            functions, will not be serialized. Such values in objects will be
            dropped; in arrays they will be replaced with null. You can use
            a replacer function to replace those with JSON values.
            JSON.stringify(undefined) returns undefined.

            The optional space parameter produces a stringification of the
            value that is filled with line breaks and indentation to make it
            easier to read.

            If the space parameter is a non-empty string, then that string will
            be used for indentation. If the space parameter is a number, then
            the indentation will be that many spaces.

            Example:

            text = JSON.stringify(['e', {pluribus: 'unum'}]);
            // text is '["e",{"pluribus":"unum"}]'


            text = JSON.stringify(['e', {pluribus: 'unum'}], null, '\t');
            // text is '[\n\t"e",\n\t{\n\t\t"pluribus": "unum"\n\t}\n]'

            text = JSON.stringify([new Date()], function (key, value) {
                return this[key] instanceof Date ?
                    'Date(' + this[key] + ')' : value;
            });
            // text is '["Date(---current time---)"]'


        JSON.parse(text, reviver)
            This method parses a JSON text to produce an object or array.
            It can throw a SyntaxError exception.

            The optional reviver parameter is a function that can filter and
            transform the results. It receives each of the keys and values,
            and its return value is used instead of the original value.
            If it returns what it received, then the structure is not modified.
            If it returns undefined then the member is deleted.

            Example:

            // Parse the text. Values that look like ISO date strings will
            // be converted to Date objects.

            myData = JSON.parse(text, function (key, value) {
                var a;
                if (typeof value === 'string') {
                    a =
/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(value);
                    if (a) {
                        return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4],
                            +a[5], +a[6]));
                    }
                }
                return value;
            });

            myData = JSON.parse('["Date(09/09/2001)"]', function (key, value) {
                var d;
                if (typeof value === 'string' &&
                        value.slice(0, 5) === 'Date(' &&
                        value.slice(-1) === ')') {
                    d = new Date(value.slice(5, -1));
                    if (d) {
                        return d;
                    }
                }
                return value;
            });


    This is a reference implementation. You are free to copy, modify, or
    redistribute.
*/

/*jslint evil: true, strict: false, regexp: false */

/*members "", "\b", "\t", "\n", "\f", "\r", "\"", JSON, "\\", apply,
    call, charCodeAt, getUTCDate, getUTCFullYear, getUTCHours,
    getUTCMinutes, getUTCMonth, getUTCSeconds, hasOwnProperty, join,
    lastIndex, length, parse, prototype, push, replace, slice, stringify,
    test, toJSON, toString, valueOf
*/


// Create a JSON object only if one does not already exist. We create the
// methods in a closure to avoid creating global variables.

var JSON;
if (!JSON) {
    JSON = {};
}

(function () {
    "use strict";

    function f(n) {
        // Format integers to have at least two digits.
        return n < 10 ? '0' + n : n;
    }

    if (typeof Date.prototype.toJSON !== 'function') {

        Date.prototype.toJSON = function (key) {

            return isFinite(this.valueOf()) ?
                this.getUTCFullYear()     + '-' +
                f(this.getUTCMonth() + 1) + '-' +
                f(this.getUTCDate())      + 'T' +
                f(this.getUTCHours())     + ':' +
                f(this.getUTCMinutes())   + ':' +
                f(this.getUTCSeconds())   + 'Z' : null;
        };

        String.prototype.toJSON      =
            Number.prototype.toJSON  =
            Boolean.prototype.toJSON = function (key) {
                return this.valueOf();
            };
    }

    var cx = /[\u0000\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
        escapable = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
        gap,
        indent,
        meta = {    // table of character substitutions
            '\b': '\\b',
            '\t': '\\t',
            '\n': '\\n',
            '\f': '\\f',
            '\r': '\\r',
            '"' : '\\"',
            '\\': '\\\\'
        },
        rep;


    function quote(string) {

// If the string contains no control characters, no quote characters, and no
// backslash characters, then we can safely slap some quotes around it.
// Otherwise we must also replace the offending characters with safe escape
// sequences.

        escapable.lastIndex = 0;
        return escapable.test(string) ? '"' + string.replace(escapable, function (a) {
            var c = meta[a];
            return typeof c === 'string' ? c :
                '\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
        }) + '"' : '"' + string + '"';
    }


    function str(key, holder) {

// Produce a string from holder[key].

        var i,          // The loop counter.
            k,          // The member key.
            v,          // The member value.
            length,
            mind = gap,
            partial,
            value = holder[key];

// If the value has a toJSON method, call it to obtain a replacement value.

        if (value && typeof value === 'object' &&
                typeof value.toJSON === 'function') {
            value = value.toJSON(key);
        }

// If we were called with a replacer function, then call the replacer to
// obtain a replacement value.

        if (typeof rep === 'function') {
            value = rep.call(holder, key, value);
        }

// What happens next depends on the value's type.

        switch (typeof value) {
        case 'string':
            return quote(value);

        case 'number':

// JSON numbers must be finite. Encode non-finite numbers as null.

            return isFinite(value) ? String(value) : 'null';

        case 'boolean':
        case 'null':

// If the value is a boolean or null, convert it to a string. Note:
// typeof null does not produce 'null'. The case is included here in
// the remote chance that this gets fixed someday.

            return String(value);

// If the type is 'object', we might be dealing with an object or an array or
// null.

        case 'object':

// Due to a specification blunder in ECMAScript, typeof null is 'object',
// so watch out for that case.

            if (!value) {
                return 'null';
            }

// Make an array to hold the partial results of stringifying this object value.

            gap += indent;
            partial = [];

// Is the value an array?

            if (Object.prototype.toString.apply(value) === '[object Array]') {

// The value is an array. Stringify every element. Use null as a placeholder
// for non-JSON values.

                length = value.length;
                for (i = 0; i < length; i += 1) {
                    partial[i] = str(i, value) || 'null';
                }

// Join all of the elements together, separated with commas, and wrap them in
// brackets.

                v = partial.length === 0 ? '[]' : gap ?
                    '[\n' + gap + partial.join(',\n' + gap) + '\n' + mind + ']' :
                    '[' + partial.join(',') + ']';
                gap = mind;
                return v;
            }

// If the replacer is an array, use it to select the members to be stringified.

            if (rep && typeof rep === 'object') {
                length = rep.length;
                for (i = 0; i < length; i += 1) {
                    if (typeof rep[i] === 'string') {
                        k = rep[i];
                        v = str(k, value);
                        if (v) {
                            partial.push(quote(k) + (gap ? ': ' : ':') + v);
                        }
                    }
                }
            } else {

// Otherwise, iterate through all of the keys in the object.

                for (k in value) {
                    if (Object.prototype.hasOwnProperty.call(value, k)) {
                        v = str(k, value);
                        if (v) {
                            partial.push(quote(k) + (gap ? ': ' : ':') + v);
                        }
                    }
                }
            }

// Join all of the member texts together, separated with commas,
// and wrap them in braces.

            v = partial.length === 0 ? '{}' : gap ?
                '{\n' + gap + partial.join(',\n' + gap) + '\n' + mind + '}' :
                '{' + partial.join(',') + '}';
            gap = mind;
            return v;
        }
    }

// If the JSON object does not yet have a stringify method, give it one.

    if (typeof JSON.stringify !== 'function') {
        JSON.stringify = function (value, replacer, space) {

// The stringify method takes a value and an optional replacer, and an optional
// space parameter, and returns a JSON text. The replacer can be a function
// that can replace values, or an array of strings that will select the keys.
// A default replacer method can be provided. Use of the space parameter can
// produce text that is more easily readable.

            var i;
            gap = '';
            indent = '';

// If the space parameter is a number, make an indent string containing that
// many spaces.

            if (typeof space === 'number') {
                for (i = 0; i < space; i += 1) {
                    indent += ' ';
                }

// If the space parameter is a string, it will be used as the indent string.

            } else if (typeof space === 'string') {
                indent = space;
            }

// If there is a replacer, it must be a function or an array.
// Otherwise, throw an error.

            rep = replacer;
            if (replacer && typeof replacer !== 'function' &&
                    (typeof replacer !== 'object' ||
                    typeof replacer.length !== 'number')) {
                throw new Error('JSON.stringify');
            }

// Make a fake root object containing our value under the key of ''.
// Return the result of stringifying the value.

            return str('', {'': value});
        };
    }


// If the JSON object does not yet have a parse method, give it one.

    if (typeof JSON.parse !== 'function') {
        JSON.parse = function (text, reviver) {

// The parse method takes a text and an optional reviver function, and returns
// a JavaScript value if the text is a valid JSON text.

            var j;

            function walk(holder, key) {

// The walk method is used to recursively walk the resulting structure so
// that modifications can be made.

                var k, v, value = holder[key];
                if (value && typeof value === 'object') {
                    for (k in value) {
                        if (Object.prototype.hasOwnProperty.call(value, k)) {
                            v = walk(value, k);
                            if (v !== undefined) {
                                value[k] = v;
                            } else {
                                delete value[k];
                            }
                        }
                    }
                }
                return reviver.call(holder, key, value);
            }


// Parsing happens in four stages. In the first stage, we replace certain
// Unicode characters with escape sequences. JavaScript handles many characters
// incorrectly, either silently deleting them, or treating them as line endings.

            text = String(text);
            cx.lastIndex = 0;
            if (cx.test(text)) {
                text = text.replace(cx, function (a) {
                    return '\\u' +
                        ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
                });
            }

// In the second stage, we run the text against regular expressions that look
// for non-JSON patterns. We are especially concerned with '()' and 'new'
// because they can cause invocation, and '=' because it can cause mutation.
// But just to be safe, we want to reject all unexpected forms.

// We split the second stage into 4 regexp operations in order to work around
// crippling inefficiencies in IE's and Safari's regexp engines. First we
// replace the JSON backslash pairs with '@' (a non-JSON character). Second, we
// replace all simple value tokens with ']' characters. Third, we delete all
// open brackets that follow a colon or comma or that begin the text. Finally,
// we look to see that the remaining characters are only whitespace or ']' or
// ',' or ':' or '{' or '}'. If that is so, then the text is safe for eval.

            if (/^[\],:{}\s]*$/
                    .test(text.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@')
                        .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
                        .replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

// In the third stage we use the eval function to compile the text into a
// JavaScript structure. The '{' operator is subject to a syntactic ambiguity
// in JavaScript: it can begin a block or an object literal. We wrap the text
// in parens to eliminate the ambiguity.

                j = eval('(' + text + ')');

// In the optional fourth stage, we recursively walk the new structure, passing
// each name/value pair to a reviver function for possible transformation.

                return typeof reviver === 'function' ?
                    walk({'': j}, '') : j;
            }

// If the text is not JSON parseable, then a SyntaxError is thrown.

            throw new SyntaxError('JSON.parse');
        };
    }
}());

(function($, generic) {
  /**
   * @description Wrap Function - Return a new function that triggers a parameter function first and
   * then moves on to the original, wrapped function.  The follow up of the original can be
   * precluded by returning false from the parameter of type function.   *
   */

  $.extend(Function.prototype, {

    /**
     * @param {function} step-ahead function to the original function being wrapped
     * @return {function} new function to be assigned to original namespace
     */
    wrap: function(fn) {
      var _generic_ = fn; // generic-level
      var _site_ = this; // site-level
      var passObj = true;

      return function() {
        passObj = _generic_.apply(fn, arguments);
        if (passObj) {
          _site_.call(this, passObj);
        } else {
          return;
        }
      };
    }
  });

  /**
   * @description Minimal Native Version of Prototype Hash Class
   *
   * @class Hash
   * @namespace generic.Hash
   *
   * @returns A public api object (get, set, etc).
   *
   */

  generic.Hash = function(obj) {
    var H = obj instanceof Object ? obj : {},
      index = [],
      _queue = [];

    var queryString = function() {
      // @inner
      var Q = function(o, v, isArr) {
        var i, S = Object.prototype.toString,
          A = '[object Array]',
          _queue = [];

        o = o || H;

        for (i in o) {
          if (typeof o[i] === 'object') {
            _queue = S.call(o[i]) === A ? Q(o[i], i, true) : Q(o[i], i);
          } else {
            n = isArr ? v : i;
            _queue.push(n + '=' + o[i]);
          }
        }

        return _queue;
      };

      return Q().join('&');
    };

    return {

      /**
       * @public get
       */
      get: function(x) {
        return H[x] || false;
      },
      /**
       * @public set
       */
      set: function(x, y) {
        H[x] = y;
        index.push(x);
        return this;
      },
      /**
       * @public toQueryString
       ** DEPRECATED **
       */
      toQueryString: queryString,
      /**
       * @public fromQueryString
       */
      queryToJson: function(q, p /*pure object, not hash*/ ) {
        var query = q;
        var k, v, i;
        var obj = {};

        var xArr = query.split('&');

        for (i = 0; i < xArr.length; i++) {
          k = xArr[i].split('=')[0];
          v = xArr[i].split('=')[1];
          evalStr = "obj['" + k + "']='" + v + "'";
          eval(evalStr);
        }

        return obj;
      },

      /**
       * @public slice
       *
       * @param {array}
       * @returns hash containing only the key/value pairs matched by the keys
       * passed in the array
       *
       */
      slice: function(array) {
        var h = $H();
        for (var i in array) {
          h.set(array[i], H[array[i]]);
        }
        return h;
      },

      obj: function() {
        return H;
      }
    }; // end api set
  };

  generic.HashFactory = function(hash) {
    var H = new generic.Hash(hash);
    return H;
  };

  /**
   * @see generic.Hash
   */
  $H = generic.HashFactory; // map convenience alias

  /**
   * Minimal Native Version of Prototype Class
   *
   * @deprecated Jquery extend method has options for deep copy extensions
   *
   * @class Class
   * @namespace generic.Class
   *
   */

  generic.Class = { // Uppercase 'Class', avoid IE errors

    fn: function(src, props) {
      var tgt, prxy, z, fnTest = /xyz/.test(function() {
        xyz;
      }) ? /\b_super\b/ : /.*/;

      tgt = function() { // New Constructor
        // Initialize Method is a Requirement of Class
        // With the inclusion of the _super method, initialize in the superclass should only be called on demand
        /**
         if(tgt.superclass&&tgt.superclass.hasOwnProperty("initialize")){
           tgt.superclass.initialize.apply(this,arguments);
         }
         */
        if (tgt.prototype.initialize) {
          tgt.prototype.initialize.apply(this, arguments);
        }
      };

      // Preserve Classical Inheritance using Proxy Middle
      src = src || Object;
      prxy = function() {}; /* Potentially define "Class" here */
      prxy.prototype = src.prototype;
      tgt.prototype = new prxy();
      tgt.superclass = src.prototype;
      tgt.prototype.constructor = tgt;

      // Give new class 'own' copies of props and add _super method to call superclass' corresponding method
      for (z in props) {
        if (typeof props[z] == 'function' && typeof tgt.superclass[z] == 'function' && fnTest.test(props[z])) {
          tgt.prototype[z] = (function(z, fn) {
            return function() {
              this._super = tgt.superclass[z];
              var ret = fn.apply(this, arguments);
              return ret;
            };
          })(z, props[z]);
        } else {
          tgt.prototype[z] = props[z];
        }
      }

      return tgt;
    },
    create: function() {
      var len = arguments.length,
        args = Array.prototype.slice.call(arguments),
        fn = generic.Class.fn;

      if (len == 2) {
        tgt = generic.Class.fn(args[0], args[1]);
      } else if (len == 1) {
        tgt = generic.Class.fn(null, args[0]);
      } else {
        tgt = function() {}; /* return empty constructor */
      }

      return tgt; // return constructor that stacks named Class w/ object-literal, works with instanceof
    }, // End Create Method
    mixin: function(baseClass, mixin) {
      var newClass = baseClass;
      if (mixin && mixin.length) {
        for (var i = 0; i < mixin.length; i++) {
          newClass = generic.Class.mixin(newClass, mixin[i]);
        }
      } else {
        if (mixin) {
          newClass = generic.Class.create(newClass, mixin);
        }
      }
      return newClass;
    }
  };

  /**
   * @memberOf generic
   *
   */

  generic.isElement = function(o) {
    return o.nodeType && (o.nodeType == 1);
  };

  /**
   * @memberOf generic
   *
   */
  generic.isString = function(s) {
    return typeof s == 'string';
  };

  /**
   * @memberOf generic
   *
   */
  generic.env = {
    isIE: !!(typeof ActiveXObject == 'function'),
    isIE6: !!(!!(typeof ActiveXObject == 'function') && /MSIE\s6\.0/.test(navigator.appVersion)),
    isFF: !!(typeof navigator.product != 'undefined' && navigator.product == 'Gecko' && !(document.childNodes && !navigator.taintEnabled) && /firefox/.test(navigator.userAgent.toLowerCase())),
    isFF2: !!(typeof navigator.product != 'undefined' && navigator.product == 'Gecko' && !(document.childNodes && !navigator.taintEnabled) && navigator.userAgent.toLowerCase().split(' firefox/').length > 1 && navigator.userAgent.toLowerCase().split(' firefox/')[1].split('.')[0] == '2'),
    isFF3: !!(typeof navigator.product != 'undefined' && navigator.product == 'Gecko' && !(document.childNodes && !navigator.taintEnabled) && navigator.userAgent.toLowerCase().split(' firefox/').length > 1 && navigator.userAgent.toLowerCase().split(' firefox/')[1].split('.')[0] == '3'),
    isMac: !!/macppc|macintel/.test(navigator.platform.toLowerCase()),
    isSafari: !!/Safari/.test(navigator.userAgent),

    domain: window.location.protocol + '//' + window.location.hostname,

    debug: true, // JSTest check subdomain

    parsedQuery: function() {
      var query = window.location.search.toString().split('?')[1] || '';
      var splitStr = query.split('&');
      var key, value, keyNameVar, tempObj, tempStr;

      var a = {};
      a.n = {};

      var main = function() {
        var params = {};
        var returnArr = [];
        var arr = [];

        if (!query) {
          return;
        }

        for (var i = 0; i < splitStr.length; i++) {
          // Just take the key
          key = splitStr[i].split('=')[0];
          value = splitStr[i].split('=')[1];

          var c = splitStr[i].match(new RegExp(key));
          var cItem = a.n[c] = a.n[c] || {
            'v': [],
            'key': c
          };
          cItem.e = cItem.e ? cItem.e + 1 : 0;
          cItem.v.push(value);
        }

        for (var namespace in a.n) {
          // if duplicate keys
          if (a.n[namespace].e > 0) {
            for (var n = 0; n <= a.n[namespace].e; n++) {
              arr.push(a.n[namespace].v.pop());
            } // end for-loop

            a.n[namespace].v = arr;
          }

          tempObj = a.n[namespace].v;
          if (tempObj.length > 1) {
            eval('params["' + namespace + '"]=tempObj');
          } else {
            tempStr = tempObj[0];
            eval('params["' + namespace + '"]=tempStr');
          }
        }

        return params;
      };

      var parameters = main() || {};
      return parameters;
    },
    query: function(key) {
      var result = generic.env.parsedQuery()[key] || null;
      return result;
    }
  };
})(jQuery, window.generic || {});
(function($, generic) {
  /**
   * This singleton class provides an interface to the Perl Gem JSON-RPC methods via AJAX.
   * @memberOf generic
   *
   * @class JsonRpc
   * @namespace generic.jsonrpc
   * @returns public object that provides the main api method - "fetch"
   */

  generic.jsonrpc = (function() {
    /**
     * @description Object literal that gets returned to provide the public api
     * @requires generic.env (dependency)
     */
    var jsonRpcObj = {

      id: 0,
      url: '/rpc/jsonrpc.tmpl',
      /**
       * @constant error codes describe scenarios for post onSuccess
       * errorHandling that points to onFailure
       */
      errorCodes: {
        101: 'The data type of this method is not supported.',
        102: 'The data type of the request parameters is not supported.',
        103: 'Your request did not return any results.',
        104: 'Response is not in the expected format.'
      },

      /**
       * @function main public api
       *
       * @param {object} Object literal with list of callbacks, onBoth for single callback regardless of
       * ajax response, and onSuccess && onFailure together to switch depending on the condition
       * of the asynchronous response.
       *
       * @returns incremented id to mark unique fetch
       */
      fetch: function( /* Object*/ args) {
        var self = this;
        this.id++;

        var bustBrowserCache = false;
        if (typeof args.bustBrowserCache === 'boolean' && !!args.bustBrowserCache) {
          bustBrowserCache = true;
        }

        /**
         * @default
         */
        var options = {
          method: 'post'
        };
        if (args.sync) {
          options.async = false;
        }

        if (args.onBoth) {
          options.onSuccess = args.onBoth;
          options.onFailure = args.onBoth;
        } else {
          options.onSuccess = args.onSuccess || function(response) {
            //console.log('JSON-RPC success');
            //console.log(JSON.parse(response.getValue()));
          };
          options.onFailure = args.onFailure || function(response) {
            //console.log('JSON-RPC failure');
            //console.log(JSON.parse(response.getMessages()));
          };
        }
        var requestRpcId = this.id; // A local copy of id for the onSuccess callback
        options.onSuccess = options.onSuccess.wrap(function(response) {
          if (!response || !response.responseText) { // empty response
            errorHandler(self.createErrorResponse(103));
            return false;
          }

          /**
           * @event RPC:RESULT is fired during the wrapping callback
           * that front-runs the site-level callbacks (which were parameters to fetch)
           */
          //generic.events.fire({event:'RPC:RESULT',msg:response});
          $(document).trigger('RPC:RESULT', [response, args, requestRpcId]);

          var responseArray = $.parseJSON(response.responseText);

          if ($.isArray(responseArray)) {
            var resultObj = responseArray[0];
            if (resultObj) {
              var jsonRpcResponse = generic.jsonRpcResponse(resultObj);
              if (resultObj.error) { // server returns an error, pass to onFailure
                errorHandler(jsonRpcResponse);
                return false;
              } else if (resultObj.result) { // successful response in expected format
                //console.log("generic.jsonrpc.onSuccess");

                return jsonRpcResponse; /* Move on to the wrapped function */
              }
            } else { // top-level response array is empty
              errorHandler(self.createErrorResponse(103));
              return false;
            }
          } else { // response is not in expected format (array)
            errorHandler(self.createErrorResponse(104));
            return false;
          }
        });

        options.onFailure = options.onFailure.wrap(function(jqXHR) {
          var resp = jqXHR;
          //server returned failure, i.e. onFailure was not triggered by this class
          if (typeof resp.responseText != 'undefined') {
            //console.log("generic.jsonRPC onFailure: server error");
            try { //server returns an error in json
              var responseArray = JSON.parse(resp.responseText);
              var resultObj = responseArray[0];
              resp = generic.jsonRpcResponse(resultObj);
            } catch (e) { //server response is not json
              //console.log("generic.jsonRPC onFailure: server error, result is not json");
              resp = self.createErrorResponse(resp.status, resp.responseText);
            }
          }
          return resp;
        });

        /**
         * @function errorHandler takes over when the generic level onSuccess concludes that
         * the rpc response fails to qualify
         *
         * @see onFailure callback
         */
        var errorHandler = options.onFailure;
        var method = args.method || 'rpc.form';
        var params = args.params || [];

        // make sure a method was passed
        if (typeof method !== 'string' || method.length <= 0) {
          errorHandler(self.createErrorResponse(101));
          return null;
        }

        //make sure that the params type is an obj
        if (typeof params === 'string') {
          params = JSON.parse(params);
        }
        if (typeof params !== 'object') {
          errorHandler(self.createErrorResponse(102));
          return null;
        }

        var postMethod = args.method || 'rpc.form';
        var postArray = [{
          'method': postMethod,
          'id': self.id,
          'params': params
        }];
        options.data = $.param({
          JSONRPC: JSON.stringify(postArray)
        });

        var url = args.url || (this.url + '?dbgmethod=' + method);
        if (bustBrowserCache) {
          url += '&cachebuster=' + Date.parse(new Date());
        }

        //url = 'jsonrpc-response-example.html'; // debug, force success example

        /* Mapping Functions */

        /**
         * @private map jquery's responses to a single, relevant param
         */
        var jqSuccess = function(data, textStatus, response) {
          return options.onSuccess.call(options, response);
        };
        var jqError = function(jqXHR, textStatus, errorThrown) {
          return options.onFailure.call(options, jqXHR);
        };

        /*
         * Jquery success property of options (object)
         *
         * success(data, textStatus, jqXHR)Function, Array
         *
         * A function to be called if the request succeeds. The function gets passed three arguments:
         * The data returned from the server, formatted according to the dataType parameter;
         * a string describing the status; and the jqXHR (in jQuery 1.4.x, XMLHttpRequest) object.
         * As of jQuery 1.5, the success setting can accept an array of functions.
         * Each function will be called in turn. This is an Ajax Event.
         *
         */
        options.success = jqSuccess;

        /*
         * Jquery error property of options (object)
         *
         * error(jqXHR, textStatus, errorThrown) Function
         *
         * A function to be called if the request fails.
         * The function receives three arguments: The jqXHR (in jQuery 1.4.x, XMLHttpRequest)
         * object, a string describing the type of error that occurred and an optional
         * exception object, if one occurred. Possible values for the second argument (besides null)
         * are "timeout", "error", "abort", and "parsererror". This is an Ajax Event.
         * As of jQuery 1.5, the error setting can accept an array of functions.
         * Each function will be called in turn.
         *
         * Note: This handler is not called for cross-domain script and JSONP requests.
         */

        options.type = 'POST';
        options.error = jqError;

        // console.log("==================");
        // console.log("===options.data===");
        // console.log(options.data);

        $.ajax(url, options);
        return this.id;
      },
      /**
       * @public Exposed api method to generate a jsonRpcResponse object with
       * "error" as the primary key.
       *
       * @param {integer} The integer value maps to a set of class constants
       * that describes the type of error.
       *
       * @param {integer, string} Overloaded method takes precedence
       * over single param.  Error code and error message passed
       * explicitly.
       *
       * @returns {object} An "error" keyed jsonRpcResponse object
       */
      createErrorResponse: function(errorCode, errorMsg) {
        errorMsg = errorMsg || this.errorCodes[errorCode];
        var errorObj = new generic.jsonRpcResponse({
          'error': {
            'code': errorCode,
            'data': {
              'messages': [{
                'text': errorMsg,
                'display_locations': [],
                'severity': 'MESSAGE',
                'tags': [],
                'key': ''
              }]
            }
          },
          'id': this.id
        });
        return errorObj;
      }

    };

    return jsonRpcObj;
  })();

  /**
   * A JsonRpcResponse object is of the expected type as parameters to the onSuccess,
   * onFailure, or onBoth callback functions.
   *
   * @memberOf generic
   *
   * @class JsonRpcResponse
   * @namespace generic.jsonRpcResponse
   * @param {object} resultObj - PerlGem RPC response formatted object
   *
   */
  generic.jsonRpcResponse = function(resultObj) {
    var jsonRpcResponseObj = {};
    var rawResponse = resultObj; // raw response data is kept in a private variable

    /**
     * @inner Constructor
     * @constructs CartItem
     */
    var CartItem = function(itemData) {
      this.product = {
        sku: {}
      };
      var prodRegEx = /^prod\.(.+)$/;
      var skuRegEx = /sku\.(.+)$/;
      var prodObj = {
        sku: {}
      };
      for (var prop in itemData) {
        var newPropName = null;
        var prodResult = prop.match(prodRegEx);
        if (prodResult && prodResult[1]) {
          newPropName = prodResult[1];
          this.product[newPropName] = itemData[prop];
        }
        if (!newPropName) {
          var skuResult = prop.match(skuRegEx);
          if (skuResult && skuResult[1]) {
            newPropName = skuResult[1];
            this.product.sku[newPropName] = itemData[prop];
          }
        }
        if (!newPropName) {
          this[prop] = itemData[prop];
        }
      }
    };

    /**
     * @inner Constructor
     * @constructs CartResult
     */
    var CartResult = function(responseData) {
      var data = responseData;
      var cartItemCount = '';
      var cartItem = {
        product: {
          sku: {}
        }
      };
      var cartMethod;
      var allItems = [];

      if (data.ac_results &&
        $.isArray(data.ac_results) &&
        data.ac_results[0]) {
        if (data.ac_results[0].result &&
          data.ac_results[0].result.CARTITEM) {
          cartItem = new CartItem(data.ac_results[0].result.CARTITEM);
        }
        if (data.ac_results[0].action) {
          cartMethod = data.ac_results[0].action;
        }
      }

      if (data.trans_data &&
        data.trans_data.order &&
        $.isArray(data.trans_data.order.items)) {
        cartItemCount = data.trans_data.items_count;
        $.each(data.trans_data.order.items, function() {
          var tempItem = new CartItem(this);
          allItems.push(this);
        });
      }
      //------------------
      // PUBLIC METHODS
      //------------------
      /**
       * @public CartResult.getAllItems
       */
      this.getAllItems = function() {
        return allItems;
      };
      /**
       * @public CartResult.getItem
       */
      this.getItem = function() {
        return cartItem;
      };
      /**
       * @public CartResult.getMethod
       */
      this.getMethod = function() {
        return cartMethod;
      };
      /**
       * @public CartResult.getCount
       */
      this.getCount = function() {
        return cartItemCount;
      };
    };

    /* Debug Method + Prop *
    jsonRpcResponseObj.getTest = function() { alert('test')} ; // temporary
    jsonRpcResponseObj.testValue = 'test val'; // temporary
    */

    /**
     * @public JsonRpcReponse.getId
     */
    jsonRpcResponseObj.getId = function() {
      if (rawResponse) {
        return rawResponse.id;
      }
      return null;
    };
    /**
     * @public JsonRpcReponse.getError
     */
    jsonRpcResponseObj.getError = function() {
      if (rawResponse &&
        rawResponse.error) {
        return rawResponse.error;
      }
      return null;
    };
    /**
     * @public JsonRpcReponse.getData
     */
    jsonRpcResponseObj.getData = function() {
      if (rawResponse &&
        rawResponse.result &&
        rawResponse.result.data) {
        return rawResponse.result.data;
      }
      return null;
    };
    /**
     * @public JsonRpcReponse.getValue
     */
    jsonRpcResponseObj.getValue = function() {
      if (rawResponse &&
        rawResponse.result &&
        typeof rawResponse.result.value != 'undefined') {
        return rawResponse.result.value;
      }
      return null;
    };
    /**
     * @public JsonRpcReponse.getMessagesError
     *
     * @description This method returns the contents of the response's error property.
     * Unlike getMessages, it will prioritize the error object
     */
    jsonRpcResponseObj.getMessagesError = function() {
      if (rawResponse) {
        if (rawResponse.error &&
          rawResponse.error.data &&
          rawResponse.error.data.messages) {
          return rawResponse.error.data.messages;
        } else if (rawResponse.result &&
          rawResponse.result.data &&
          rawResponse.result.data.messages) {
          return rawResponse.result.data.messages;
        }
      }
      return null;
    };
    /**
     * @public JsonRpcReponse.getMessages
     *
     * @description This method returns the contents of the response's error property.
     * It first checks the result property, then checks the error property.
     */
    jsonRpcResponseObj.getMessages = function() {
      if (rawResponse) {
        if (rawResponse.result &&
          rawResponse.result.data &&
          rawResponse.result.data.messages) {
          return rawResponse.result.data.messages;
        } else if (rawResponse.error &&
          rawResponse.error.data &&
          rawResponse.error.data.messages) {
          return rawResponse.error.data.messages;
        }
      }
      return null;
    };
    /**
     * @public JsonRpcReponse.getCartResults
     */
    jsonRpcResponseObj.getCartResults = function() {
      var data = this.getData();
      if (!data) {
        return null;
      }
      var returnObj = new CartResult(data);
      return returnObj;
    };

    return jsonRpcResponseObj;
  };

  /*
   * generic.onLoadRpcRequests a global array of RPC request objects
   * must be initialized pre-DOM-load and formatted like this:
   * [
   *     {
   *         "method":   "user.json",
   *         "params":   [{}],
   *         "onSuccess" : function () { },
   *         "onFailure" : function () { }
   *     }
   * ]
   *
   */
  $(function() {
    // TODO Modify generic.jsonrpc to allow multiple methods
    // on one request, then use it for this Ajax call. 
    var requests = generic.onLoadRpcRequests || [];
    var requestsLen = requests.length;
    var queryVals = [];

    for (var i = 0, len = requestsLen; i < len; i++) {
      var postMethod = requests[i]['method'] || 'rpc.form';
      queryVals[i] = {
        'method': postMethod,
        'params': requests[i].params,
        'id': i + 1
      };
    }

    if (queryVals.length == 0) {
      return null;
    }

    var successHandler = function(data, textStatus, response) {
      for (var i = 0, len = requests.length; i < len; i++) {
        var fn = requests[i].onSuccess;
        if (typeof fn !== 'function') {
          continue;
        }
        fn(data[i]);
      }
    };

    var url = '/rpc/jsonrpc.tmpl';
    var options = {};

    // ELCTWO-571 requires that we pass brand, region, and locale ids to ensure proper responses 
    // on the pg side for drupal sites.  To accomplish this we pass 'addl_url_params' within the arguments.
    // This snippets searches for such entries and adds 'em to the request url.
    var url_params = '';
    $(queryVals).each(function() {
      if (this.params[0].url_params) {
        if (this.params[0].url_params.charAt(0) === '&') {
          url_params += this.params[0].url_params;
        } else {
          url_params += '&' + this.params[0].url_params;
        }
      }
    });
    if (url_params !== '') {
      url += '?' + url_params.substring(1);
    }

    options.data = $.param({
      JSONRPC: JSON.stringify(queryVals)
    });

    options.type = 'POST';
    options.success = function(data, textStatus, response) {
      // console.log("Ajax success :::::::::::::::::::::");
      // console.log(arguments);

      successHandler(data, textStatus, response);
    };
    options.error = function(jqXHR, textStatus, errorThrown) {
      // console.log("Ajax error :::::::::::::::::::::");
      // console.log(arguments);
    };

    $.ajax(url, options);
  });
})(jQuery, window.generic || {});

(function($, generic) {
  generic.forms = {
    select: {
      addOption: function(args) {
        if (!args) {
          return;
        }
        var val = args.value;
        var label = args.label || val;
        var opt = '<option value="' + val + '">' + label + '</option>';
        args.menuNode.append(opt);
      },
      setValue: function(args) {
        var idx = 0;
        for (var i = 0, len = args.menuNode[0].options.length; i < len; i++) {
          if (args.value == args.menuNode[0].options[i].value) {
            idx = i;
            break;
          }
        }
        args.menuNode[0].selectedIndex = idx;
      }
    }
  };
})(jQuery, window.generic || {});

(function(generic) {
  generic.cookie = function(/*String*/name, /*String?*/value, /*.__cookieProps*/props) {
    var c = document.cookie;
    if (arguments.length == 1) {
      var matches = c.match(new RegExp('(?:^|; )' + name + '=([^;]*)'));
      if (matches) {
        matches = decodeURIComponent(matches[1]);
        try {
          return jQuery.parseJSON(matches); //Object
        } catch (e) {
          return matches; //String
        }
      } else {
        return undefined;
      }
    } else {
      props = props || {};
      // FIXME: expires=0 seems to disappear right away, not on close? (FF3)  Change docs?
      var exp = props.expires;
      if (typeof exp == 'number') {
        var d = new Date();
        d.setTime(d.getTime() + exp * 24 * 60 * 60 * 1000);
        exp = props.expires = d;
      }
      if (exp && exp.toUTCString) {
        props.expires = exp.toUTCString();
      }

      value = encodeURIComponent(value);
      var updatedCookie = name + '=' + value;

      for (propName in props) {
        updatedCookie += '; ' + propName;
        var propValue = props[propName];
        if (propValue !== true) {
          updatedCookie += '=' + propValue;
        }
      }

      document.cookie = updatedCookie;
    }
  };
})(window.generic || {});

(function($, generic, rb) {
  generic.rb = generic.rb || {};

  /**
   * This method provides access to resource bundle values that have been
   * written to the HTML in JSON format. The file that outputs these values
   * must be included in the .html as a script tag with the desired RB name
   * as a query string paramter.
   *
   * @class ResourceBundle
   * @namespace generic.rb
   *
   * @memberOf generic
   * @methodOf generic
   * @requires generic.Hash (minimal functional replication of Prototype Hash Class)
   *
   * @example Inline data
   *
   * <script src="/js/shared/v2/internal/resource.tmpl?rb=account"></script>
   *
   * @example Script retrival of data values
   *
   * var myBundle = generic.rb("account");
   * myBundle.get("err_please_sign_in");   *
   *
   * @param {String} rbGroupName name of resource bundle needed   *
   * @returns An object that provides the main get method
   *
   */
  generic.rb = function(rbGroupName) {
    var findResourceBundle = function(groupName) {
      if (groupName && rb) {
        var rbName = groupName;
        var rbHash = generic.Hash(rb[rbName]);
        if (rbHash) {
          return rbHash;
        } else {
          return $H({});
        }
      } else {
        return $H({});
      }
    };

    var resourceBundle = findResourceBundle(rbGroupName);

    var returnObj = {
      /**
       * @public This method will return the value for the requested Resource Bundle key.
       * If the key is not found, the key name will be returned.
       *
       * @param {String} keyName key of desired Resource Bundle value
       */
      get: function(keyName) {
        if (!generic.isString(keyName)) {
          return null;
        }
        var val = resourceBundle.get(keyName);
        if (val) {
          return val;
        } else {
          return keyName;
        }
      }
    };

    return returnObj;
  };
})(jQuery, window.generic || {}, window.rb || {});
(function($, generic) {

    generic.checkout = {};

    /**
     * The cart is a singleton. Multicart functionality needs to be extended,
     * where this singleton can provide a single reference to manage n carts.
     *
     * @class Cart
     * @namespace generic.checkout.cart
     *
     * @requires generic.cookie, generic.jsonrpc, generic.Hash
     *
     * @returns singleton cart object
     *
     */
    generic.checkout.Cart = (function() {

      /**
       * @private declared dependencies of other js modules
       */
      var Hash = generic.Hash,
        JsonRpc = generic.jsonrpc,
        Cookie = generic.cookie;

      /**
       * @private singleton
       */
      var cart;

      /**
       * @private private classes for mixin to service final api {}
       */
      var Properties = {
          setCookie: false
        },
        Containers = {
          order: new Hash(),
          payments: new Array(),
          carts: new Hash(),
          items: new Array(),
          samples: new Array()
        },
        CartData = {
          totalShoppedItems: 0,
          totalItems: 0
        },
        /**
         * @constant
         */
        CartConstants = {

          transactionParams: {
            transactionFields: {
              "trans_fields": ["TRANS_ID", "payments"]
            },
            paymentFields: {
              "payment_fields": ["address", "PAYMENT_TYPE", "PAYMENT_AMOUNT", "TRANS_PAYMENT_ID"]
            },
            orderFields: {
              "order_fields": ["items", "samples", "address", "TRANS_ORDER_ID"]
            }
          },
          itemTypes: {
            "cart": {
              "id": "SKU_BASE_ID",
              "_SUBMIT": "cart"
            },
            "GiftCard": {
              "id": "GiftCard",
              "_SUBMIT": "giftcard"
            },
            "collection": {
              "id": "SKU_BASE_ID",
              "_SUBMIT": "collection.items"
            },
            "kit": {
              "id": "COLLECTION_ID",
              "_SUBMIT": "alter_collection"
            },
            "replenishment": {
              "id": "SKU_BASE_ID",
              "_SUBMIT": "alter_replenishment"
            },
            "favorites": {
              "id": "SKU_BASE_ID",
              "_SUBMIT": "alter_collection"
            }
          }
        },
        Ops = {
          /**
           * @private update cart state
           */
          _updateCartData: function(data) {
            var self = this;
            this.data = data;
            this.totalItems = data.items_count;
            this.defaultCartId = data.default_cart_id;
            // ATTENTION $A needs work
            this.payments = (data.trans && data.trans.payments) ? $A(data.trans.payments) : null;
            this.order = data.order;

            // Contents and sample_contents mirror the sku by qty hashes
            this.order.contents = new Hash();
            this.order.sample_contents = new Hash();

            if (this.order.items != null) {
              this.order.items = $.map(this.order.items,
                function(ele) { // Filter out nulls
                  return (ele == null ? null : ele)
                }
              );
            }

            var items = this.order.items || null;
            var totalShoppedItems = 0;
            if (items != null) {
              $.each(items, function() {
                if (!this) {
                  return;
                }
                totalShoppedItems += this.ITEM_QUANTITY;

                // Set up contents by cart hashes
                var cartID = this.CART_ID;
                var cart = self.carts.get(cartID);
                if (!cart) {
                  self.carts.set(cartID, new Hash());
                  cart = self.carts.get(cartID);
                  cart.set('contents', new Hash());
                }
                var id = this['sku.SKU_BASE_ID'] ? this['sku.SKU_BASE_ID'] : this.COLLECTION_ID;
                cart.get('contents').set(id, this.ITEM_QUANTITY);

                // Compute per-unit tax (replace this with field from JSONRPC result when available)
                var unitTax = this.APPLIED_TAX / this.ITEM_QUANTITY;
                this.UNIT_TAX = unitTax;

                // Set up order contents hash (spans carts)
                if (this.itemType.toLowerCase() == 'skuitem') {
                  var key = this['sku.SKU_BASE_ID'];
                  var qty = this.ITEM_QUANTITY;
                  // Error self.order.contents.set(key, qty);
                  self.order.contents[key] = qty;
                } else if (this.itemType.toLowerCase() == 'kititem') {
                  var key = this.COLLECTION_ID;
                  var qty = this.ITEM_QUANTITY;
                  self.order.contents.set(key, qty);
                } else {
                  // FUTURE: other cart item types (e.g. kits)
                }

              });
            }

            this.totalShoppedItems = totalShoppedItems;

            var samples = this.order.samples;
            if (samples != null) {
              $.each(samples, function() {
                // set up contents by cart hashes
                var cartID = this.CART_ID;
                var cart = self.carts.get(cartID);

                if (!cart) {
                  self.carts.set(cartID, new Hash());
                  cart = self.carts.get(cartID);
                  cart.set('contents', new Hash());
                }

                var id = this['sku.SKU_BASE_ID'] ? this['sku.SKU_BASE_ID'] : this.COLLECTION_ID;
                cart.get('contents').set(id, this.ITEM_QUANTITY);

                // Set up order contents hash (spans carts)
                if (this.itemType.toLowerCase() == 'sampleitem') {
                  var key = this['sku.SKU_BASE_ID'];
                  var qty = this.ITEM_QUANTITY;
                  self.order.sample_contents.set(key, qty);
                } else {
                  // Other item types (are likely errors)
                }
              });
            }
          }
        },
        /**
         * @inner Api class with all the methods to handle cart
         */
        API = {
          initialize: function(args) {
            $.extend(this, args);
          },
          /**
           * @public getCartTotals
           */
          getCartTotals: function() {

            var cookie = Cookie("cart");
            if (cookie && cookie !== null) {
              $.extend(this, cookie);

              /**
               * @events cart:countsUpdated
               */
            } else {
              this.getCart();
            }

          },
          /**
           * @public setCookie
           */
          setCookie: function() {
            var s = {
              totalItems: this.totalItems
            }
            s = JSON.stringify(s);
            Cookie("cart", s, {
              path: "/"
            });
          },
          /**
           * @public getCart
           * @returns id of updated cart
           */
          getCart: function(args) {

            var self = this;

            if (args != null && args.pageDataKey) {
              var pageData = generic.page_data(args.pageDataKey);
              if (pageData.get("rpcdata")) {
                self._updateCartData(pageData.get("rpcdata"));
                return;
              }
            }

            var params = {};
            $.extend(params, self.transactionParams.transactionFields);
            $.extend(params, self.transactionParams.paymentFields);
            $.extend(params, self.transactionParams.orderFields);

            var id = generic.jsonrpc.fetch({
              method: 'trans.get',
              params: [params],
              onSuccess: function(jsonRpcResponse) {
                self._updateCartData(jsonRpcResponse.getValue());
              },
              onFailure: function(jsonRpcResponse) {
                console.log('Transaction JSON failed to load');
              }
            });
            return id;

          },
          /**
           * @public updateCart
           *
           * @param {object} onSuccess, onFailure callbacks
           *
           * @returns {number} incremented id uniquely identifying internal operations
           */
          updateCart: function(args) {

            if (!args.params) return null;

            var self = this;
            var onSuccess = args.onSuccess || new(function() {})(); // native empty function
            var onFailure = args.onFailure || new(function() {})(); // prev: prototype.emptyFunction

            var itemType = args.params.itemType || "cart"; //e.g. cart, collection, giftcard etc
            var id = self.itemTypes[itemType].id;
            var method = 'rpc.form';

            var params = {
              '_SUBMIT': self.itemTypes[itemType]["_SUBMIT"]
            }; // not-yet args.params

            //id // single id or collection id based on sku array from params
            if (id == 'SKU_BASE_ID') {
              // params[id] = (args.params.skus.length == 1) ? args.params.skus[0] : args.params.collectionId; //MK collections array syntax correct?
              params[id] = args.params.skus;
            } else if (id == 'COLLECTION_ID') {
              params[id] = args.params.collectionId;
            }

            //qty
            if (args.params.INCREMENT && args.params.INCREMENT >= 0) {
              //currently +1 will be added regardless of INCREMENT's actual value
              //backend requires QTY property to exist but it will not be used
              params["INCREMENT"] = args.params.INCREMENT;
              params["QTY"] = 1;
            } else if (args.params.INCREMENT && args.params.INCREMENT < 0) {
              //decrements qty by -1
            }
            if (args.params.QTY && args.params.QTY >= 0) {
              params["QTY"] = args.params.QTY;
            }

            //offer code
            if (args.params.OFFER_CODE && args.params.OFFER_CODE.length > 0) {
              params['OFFER_CODE'] = args.params.OFFER_CODE;
            }

            //favorites
            if (args.params.action && args.params.action.length > 0) {
              params['action'] = 'add';
            }

            //kit
            if (args.params.action && args.params.action == 'save') {
              params['action'] = 'save';
            }

            //replenishment
            if (args.params.REPLENISHMENT_FREQ && args.params.REPLENISHMENT_FREQ >= 0) {
              params['REPLENISHMENT_FREQ'] = args.params.REPLENISHMENT_FREQ;
            }
            if (args.params.add_to_cart && args.params.add_to_cart != 0) {
              params['add_to_cart'] = args.params.add_to_cart;
            }

            //giftcard
            if (args.params.ITEM_TYPE && args.params.ITEM_TYPE == 'GiftCard') {
              $.extend(params, args.params);
            }

            // targeting of the correct cart is still missing (and important to get right)
            // cart id if we are adding to something other than the default cart
            if (args.params.cart_id && (args.params.cart_id != self.defaultCartId)) {
              params['CART_ID'] = args.params.cart_id;
            }

            //method
            if (args.params.method && args.params.method.length > 0) {
              method = args.params.method;
            }

            // Save which catId the prod was displayed in
            if (args.params.CAT_BASE_ID && args.params.CAT_BASE_ID.length > 0) {
              params["CAT_BASE_ID"] = args.params.CAT_BASE_ID;
            }

            var id = JsonRpc.fetch({
              "method": method,
              "params": [params], // [{}]
              "onSuccess": function(jsonRpcResponse) {

                var data = jsonRpcResponse.getData();
                var cartResultObj = jsonRpcResponse.getCartResults();
                //load data
                if (data && data["trans_data"]) {
                  self._updateCartData(data["trans_data"]);
                }
                if (args.params.itemType == 'cart') {
                  // $(document).trigger("cart.updated", [cartResultObj]);
                };
                if (args.params.itemType == 'favorites') {
                  /**
                   * @event favorites:updated
                   */

                  $(document).trigger("favorites.updated", [jsonRpcResponse]);
                };
                if (args.params.itemType == 'kit') {
                  /**
                   * @event kit:updated
                   */

                  $(document).trigger("kit.updated", [jsonRpcResponse]);
                };
                if (args.params.itemType == 'replenishment') {
                  $(document).trigger("cart.updated", [cartResultObj]);
                };
                onSuccess(jsonRpcResponse);

              },
              "onFailure": function(jsonRpcResponse) {
                onFailure(jsonRpcResponse);
              }
            });

            return id;

          },
          /**
           * @public getItemQty
           * @returns {number}
           */
          getItemQty: function(baseSkuId) {
            if (!this.order.items) return 0;
            for (i in this.order.items) {

              if (i['sku.SKU_BASE_ID'] == baseSkuId) {

                var lineItem = i;
                break;
              }

            }
            if (!lineItem) return 0;
            return lineItem.ITEM_QUANTITY;
          },
          /**
           * @public getBaseSkuIds
           * @returns {array}
           */
          getBaseSkuIds: function() { // MK: what is this used for?
            if (!this.order.items) return new Hash();
            var baseSkuIds = [];
            for (i in this.order.items) {
              baseSkuIds.push(i['sku.SKU_BASE_ID']);
            }
            return baseSkuIds;
          },
          /**
           * @public getSubtotal
           * @returns {number}
           */
          getSubtotal: function() {
            var lineItems = this.order.items;
            if (!this.order.items) return 0;
            var subtotal = 0;
            for (var i = 0, len = lineItems.length; i < len; i++) {
              var lineItem = lineItems[i];
              subtotal += (lineItem.UNIT_PRICE + lineItem.UNIT_TAX) * lineItem.ITEM_QUANTITY;
            }
            return subtotal;
          },
          /**
           * @public getTotalShoppedItems
           * @returns {number}
           */
          getTotalShoppedItems: function() { //products and gift cards
            return this.totalShoppedItems;
          },
          /**
           * @public getTotalSamples
           * @returns {number}
           */
          getTotalSamples: function() {
            var ttl = 0;
            var samples = this.order.samples;
            if (samples != null) {
              samples.each(function(item) {
                ttl += item.ITEM_QUANTITY;
              });
            }
            return ttl;
          },
          /**
           * @public getTotalItems
           * @returns {number}
           */
          getTotalItems: function() {
            return this.totalItems;
          }

        };

      cart = $.extend(cart, API, Ops, CartConstants, CartData, Containers, Properties);

      var extra = {
        sample: function() {
          alert('sample');
        }
      }

      return function() {

        if (cart) {
          return cart;
        }

        // Initial and only-time singleton reference
        cart = $.extend(this, cart);
        cart.api = extra.sample;

      };

    }());

    generic.checkout.cart = new generic.checkout.Cart();

})(jQuery, window.generic || {});
(function($, generic) {
  generic.errorStateClassName = 'error';

  /**
   * This method displays error messages. It takes an array of error objects and a UL node
   * as parameters. If the UL is not spuulied, it will attempt to find a <UL class="error_messages">
   * in the DOM. It will then attempt to insert one directly after a <DIV id="header"> (If no header
   * is found, the method exits.) All the LI child nodes (previous messages) of the UL are hidden.
   * The text property of each error Hash is then displayed as an LI.
   * This method can also alter the style of the input elements that triggered the error.
   * The tags property in an error hash must be an array that contains a string starting with
   * "field." If the optional formNode parameter is supplied, this form node will be
   * searched for the field, and that field will be passed to the generic.showErrorState method.
   * @example
   * var errArray = [
   *   {
   *     "text": "Last Name Alternate must use Kana characters.",
   *     "display_locations": [],
   *     "severity": "MESSAGE",
   *     "tags": ["FORM", "address", "field.last_name_alternate"],
   *     "key": "unicode_script.last_name_alternate.address"
   *   },
   *   {
   *     "text": "First Name Alternate must use Kana characters.",
   *     "display_locations": [],
   *     "severity": "MESSAGE",
   *     "tags": ["FORM", "address", "field.first_name_alternate"],
   *     "key": "unicode_script.first_name_alternate.address"
   *   }
   * ];
   * var listNode = $$("ul.error_messages")[0];
   * generic.showErrors(errArray, listNode);
   * @param {Array} errorObjectsArray Array of error hashes.
   * @param {DOM Node UL} errListNode UL element in which the error messages will be displayed.
   * @param {DOM Node} formNode Form element (or any container node) that contains the inputs
   * to be marked as being in an error state.
   */
  generic.showErrors = function(errorObjectsArray, errListNode, formNode) {
    var ulNode = errListNode != null ? errListNode : $('ul.error_messages');
    // prototype version acted on a single node. This could be a list
    // so cut it down to avoid redundant messaging in places. i.e - signin
    ulNode = $(ulNode[0]);

    // TEST that pre-exisiting ul.error_messages is selected as container
    if ($('ul.error_messages').length == 0) {
      var header = $('div#header');
      if (header.length == 0) {
        return null;
        // TEST that DOM w/o div#header gets no error list (no ulNode parameter in method call)
      } else {
        $("<ul class='error_messages'></ul>").insertAfter(header);
        ulNode = $('.error_messages');
        // TEST that DOM with div#header adds ul.error_messages after div#header)
      }
    }
    var errListItemNodes = ulNode.children('li');

    errListItemNodes.hide();
    // TEST that pre-exisiting, visible ul.error_messages LI's are hidden
    if (errorObjectsArray.length > 0) {
      // Hide all error states on fields
      formNode = $(formNode);
      var inputNodes = formNode.children('input, select, label');
      inputNodes.each(function() {
        generic.hideErrorState(this);
      });
      // TEST setup: input, select, label elements in formNode have class name "error"
      // test that the class name gets removed from those elements
    }
    for (var i = 0, len = errorObjectsArray.length; i < len; i++) {
      var errObj = errorObjectsArray[i];
      var errKey = errObj.key;
      var errListItemNode = [];
      if (errKey) {
        var regEx = new RegExp(errKey);
        // Try to find LI whose ID matches the error key
        errListItemNode = errListItemNodes.filter(function() {
          return regEx.test(this.id);
        });
      }

      if (errListItemNode.length > 0) {
        // TEST setup: LI with id that matches error key is already in UL, hidden.
        // Test that the LI gets shown when matching key is found amoung error objects.
        errListItemNode.show();
      } else {
        // TEST setup: no matching LI is in UL. LI gets created with matching key and added to UL.
        errListItemNode = $('<li/>');
        errListItemNode.html(errObj.text);
        ulNode.append(errListItemNode);
      }
      if (errObj.displayMode && errObj.displayMode === 'message') {
        // TEST setup: error object has displayMode="message"
        // Test that matching LI gets class name "message"
        errListItemNode.addClass('message');
      }
      if (errObj.tags && $.isArray(errObj.tags)) {
        // Search through error objects, show error state for any tagged with "field.[NAME]"
        var fieldPrefixRegEx = /^field\.(\w+)$/;
        for (var j = 0, jlen = errObj.tags.length; j < jlen; j++) {
          var tag = errObj.tags[j];
          var reResults = tag.match(fieldPrefixRegEx);
          if (reResults && reResults[1]) {
            var fieldName = reResults[1].toUpperCase();
            var inputNode = $('input[name=' + fieldName + '], select[name=' + fieldName + ']', formNode);
            if (inputNode.length > 0) {
              generic.showErrorState(inputNode[0]);
              var labelNode = $('label[for=' + inputNode[0].id + ']', formNode);
              generic.showErrorState(labelNode[0]);
            }
          }
        }
        // TEST setup: error object has "tags" = ["field.last_name"]; formNode contains a label & an input tag with name=last_name
        // Test that the tags get className "error"
      }
    }
    ulNode.show();
    ulNode.addClass('error_messages_display');
    // TEST ulNode should be visible & have classname = error_messages_display
  };

  generic.showErrorState = function( /* DOM Node */ inputNode) {
    if (!inputNode || !generic.isElement(inputNode)) {
      return null;
    }
    $(inputNode).addClass(generic.errorStateClassName);
  };

  generic.hideErrorState = function( /* DOM Node */ inputNode) {
    if (!inputNode || !generic.isElement(inputNode)) {
      return null;
    }
    $(inputNode).removeClass(generic.errorStateClassName);
  };
})(jQuery, window.generic || {});